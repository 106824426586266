<template>
  <div v-loading="isTermContractTableLoading">
    <el-table class="div-main-list-page-table div-order-table" v-if="!isTermContractTableLoading" :data="orderTableData" height="100%" :cell-style="{padding: '0', height: '80px'}">
      <el-table-column width="55" v-if="hasRadio">
        <template v-slot="scope">
          <el-radio :label="scope.row" v-model="orderRadioData" @change="handleTermContractRadioSelectChange"><i></i></el-radio>
        </template>
      </el-table-column>
        <el-table-column label="Contract No." prop="contractNo" show-overflow-tooltip/>
        <el-table-column label="Reference No." show-overflow-tooltip>
          <template v-slot="scope">
            {{ isLinkedSalesTermContract ? (scope.row.sellerReferenceNo || '-') : (scope.row.buyerReferenceNo || '-') }}
          </template>
        </el-table-column>
        <el-table-column label="Contract Type">
          <template v-slot="scope">
            {{capitalizeFirstLetter(scope.row.termContractType, '_')}}
          </template>
        </el-table-column>
        <el-table-column label="Company" show-overflow-tooltip="">
          <template v-slot="scope">
            {{ ((!parent && isLinkedSalesTermContract) || parent === 'LinkedSalesOrder') ? scope.row.buyerCompanyName : scope.row.sellerCompanyName}}
          </template>
        </el-table-column>
        <el-table-column label="Start Date">
          <template v-slot="scope">
            {{$moment(scope.row.contractStartDate).format('DD-MM-YYYY')}}
          </template>
        </el-table-column>
        <el-table-column label="End Date" prop="contractEndDate">
          <template v-slot="scope">
            {{$moment(scope.row.contractEndDate).format('DD-MM-YYYY')}}
          </template>
        </el-table-column>
        <el-table-column label="" width="50px" fixed="right" v-if="hasRightMenu">
          <template v-slot="scope">
            <el-button type="danger" icon="el-icon-delete" size="mini" circle :disabled="isRightMenuDisabled" @click="handleOrderTableRightMenuClick"/>
          </template>
        </el-table-column>
      </el-table>
  </div>
</template>
<script>
import { capitalizeFirstLetter } from '@/utils/index.js'
export default {
  name: 'TableTermContract',
  props: {
    hasRightMenu: Boolean,
    isRightMenuDisabled: Boolean,
    hasRadio: Boolean,
    isTermContractTableLoading: Boolean,
    orderTableData: Array,

    isLinkedSalesTermContract: Boolean,
    linkedSalesTermContract: Object,
    linkedPurchaseTermContract: Object,
    parent: String,
    termContractPagination: Object
  },
  data () {
    return {
      orderRadioData: null
    }
  },
  created () {
    if (this.isLinkedSalesTermContract && this.linkedSalesTermContract) {
      this.orderRadioData = this.orderTableData.find(d => d.id === this.linkedSalesTermContract.id)
    } else if (!this.isLinkedSalesTermContract && this.linkedPurchaseTermContract) {
      this.orderRadioData = this.orderTableData.find(d => d.id === this.linkedPurchaseTermContract.id)
    }
  },
  methods: {
    capitalizeFirstLetter,
    handleTermContractRadioSelectChange (selection) {
      this.$emit('handleTermContractRadioSelectChange', selection)
    },
    handleOrderTableRightMenuClick () {
      this.$emit('handleOrderTableRightMenuClick', this.parent)
    }
  }
}
</script>
