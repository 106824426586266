var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isTermContractTableLoading,
          expression: "isTermContractTableLoading",
        },
      ],
    },
    [
      !_vm.isTermContractTableLoading
        ? _c(
            "el-table",
            {
              staticClass: "div-main-list-page-table div-order-table",
              attrs: {
                data: _vm.orderTableData,
                height: "100%",
                "cell-style": { padding: "0", height: "80px" },
              },
            },
            [
              _vm.hasRadio
                ? _c("el-table-column", {
                    attrs: { width: "55" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: scope.row },
                                  on: {
                                    change:
                                      _vm.handleTermContractRadioSelectChange,
                                  },
                                  model: {
                                    value: _vm.orderRadioData,
                                    callback: function ($$v) {
                                      _vm.orderRadioData = $$v
                                    },
                                    expression: "orderRadioData",
                                  },
                                },
                                [_c("i")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3473370989
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  label: "Contract No.",
                  prop: "contractNo",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Reference No.", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isLinkedSalesTermContract
                                  ? scope.row.sellerReferenceNo || "-"
                                  : scope.row.buyerReferenceNo || "-"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2926918639
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Contract Type" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.capitalizeFirstLetter(
                                  scope.row.termContractType,
                                  "_"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2052319099
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Company", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                (!_vm.parent &&
                                  _vm.isLinkedSalesTermContract) ||
                                  _vm.parent === "LinkedSalesOrder"
                                  ? scope.row.buyerCompanyName
                                  : scope.row.sellerCompanyName
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3679534004
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Start Date" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .$moment(scope.row.contractStartDate)
                                  .format("DD-MM-YYYY")
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  490883106
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "End Date", prop: "contractEndDate" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .$moment(scope.row.contractEndDate)
                                  .format("DD-MM-YYYY")
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3038181613
                ),
              }),
              _vm.hasRightMenu
                ? _c("el-table-column", {
                    attrs: { label: "", width: "50px", fixed: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-delete",
                                  size: "mini",
                                  circle: "",
                                  disabled: _vm.isRightMenuDisabled,
                                },
                                on: {
                                  click: _vm.handleOrderTableRightMenuClick,
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2406040727
                    ),
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }